@use '@on3/styles/variables' as var;

.container {
  background: var.$white;
  padding: 10px;
  border-bottom: 1px solid var.$lightNeutral;
  width: 100%;
}

.link {
  display: flex;
  width: 100%;

  svg {
    justify-self: flex-end;
    align-self: flex-start;
    margin-left: 10px;
    height: 20px;
  }
}

.title {
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  font-style: normal;
}
