@use '@on3/styles/mixins' as mix;

@use '@on3/styles/variables' as var;

.stars {
  @include mix.flex;

  svg {
    fill: var.$neutral;
    width: 1rem;

    @media screen and (min-width: var.$small-width) {
      width: 1.25rem;
    }

    @media screen and (min-width: var.$tablet-width) {
      width: auto;
    }
  }

  .active {
    fill: var.$gold;
  }
}
