@use '@on3/styles/base';

@use '@on3/styles/mixins' as mix;

@use '@on3/styles/variables' as var;

:export {
  on3Primary: var.$on3primary;
}

.block {
  @include mix.flex($justify: 'center');

  width: 100%;
  padding: var.$spacing-regular var.$spacing-regular 0;
  position: relative;

  &::after {
    position: absolute;
    width: 100%;
    bottom: 0;
    content: '';
    height: 50%;
    z-index: -1;
    background: var.$on3night;
  }

  @media screen and (min-width: var.$mid-width) {
    padding: var.$spacing-regular var.$spacing-lg 0;
  }

  section {
    @include mix.flex($direction: 'column', $gap: var.$spacing-sm);

    background: var.$focusBackground;
    padding: var.$spacing-regular;
    max-width: 1250px;

    @media screen and (min-width: var.$mid-width) {
      padding: var.$spacing-lg;
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: var.$spacing-lg;
    }

    header {
      @include mix.flex($direction: 'column', $align: 'flex-start');

      flex-grow: 1;

      h2 {
        @extend %h3;

        font-size: clamp(1.25rem, 2vw, 1.5rem);
        line-height: 2;
      }

      p {
        font-size: 14px;
        letter-spacing: 0.35px;
        line-height: 1.25;
      }
    }
  }

  .container {
    flex-grow: 1;
    width: 100%;

    .message {
      text-align: center;
      font-size: 1rem;
      font-weight: 600;
      color: var.$newGreen;

      &[data-type='error'] {
        color: var.$negativeDay;
      }
    }

    form {
      @include mix.flex(
        $direction: 'column',
        $gap: var.$spacing-sm,
        $align: 'stretch'
      );

      > div {
        @include mix.flex(
          $direction: 'column',
          $gap: var.$spacing-sm,
          $align: 'stretch'
        );

        @media screen and (min-width: var.$mid-width) {
          flex-direction: row;

          > div {
            flex-grow: 1;
          }
        }
      }

      input {
        background-color: var.$white;
      }

      button {
        @extend %btn;

        font-family: var.$geist;
        color: var.$white;
        padding: var.$spacing-sm var.$spacing-regular;
        text-wrap: nowrap;
      }
    }
  }

  .terms {
    color: var.$gray;
    font-size: 0.75rem;
    letter-spacing: 0.35px;

    @media screen and (min-width: var.$mid-width) {
      grid-column: span 2;
    }

    a {
      text-decoration: underline;
    }
  }
}
