@use '@on3/styles/base';

@use '@on3/styles/variables' as var;

@use '@on3/styles/mixins' as mix;

.block {
  h3 {
    @extend %h3, %subheading;

    margin-bottom: var.$spacing-compact;
  }

  .schedule {
    width: 100%;
    background: #fff;
    border-radius: var.$radius-sm;
    padding: var.$spacing-sm 0;
    border-bottom: 1px solid var.$lightNeutralGray;

    @include mix.spaceY(1rem);
  }

  .footer {
    width: 100%;
    margin-top: var.$spacing-sm;

    @include mix.flex($justify: space-between);
  }

  .link {
    font-size: 0.625rem;
    font-weight: 600;
    letter-spacing: 0.65px;
    text-transform: uppercase;
  }

  .disclaimer {
    @extend %small-gray-text;
  }
}
