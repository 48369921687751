@use '@on3/styles/base';

@use '@on3/styles/variables' as var;

.moduleContainer {
  margin-bottom: 50px;
  padding: 0 15px;
}

.title {
  @extend %h3, %subheading;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  text-transform: capitalize;
  margin-bottom: var.$spacing-compact;
}

.titleIcon {
  margin: 0 4px;
}

.headlines {
  display: flex;
  border-bottom: 1px solid var.$lightNeutral;
  padding: 8px 0;
  align-items: center;
}

.icon {
  fill: var.$premiumGreen;
  height: auto;
  width: 20px;
  min-width: 20px;
}

.text,
.unlockText {
  color: var.$gray-2;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.35px;
  margin-left: 5px;
}

.unlockText {
  text-align: center;
}

.subscribeWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  padding: 10px 0;
  border-bottom: 1px solid var.$lightNeutral;
}

.subscribeText {
  font-family: var.$geist;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0.35px;
  text-align: center;
}

.listIcon {
  padding-top: 5px;
  margin-left: 5px;
}

.subButton {
  border-radius: 2px;
  padding: 4px 20px;
  color: var.$on3night;
}
