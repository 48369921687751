@use '@on3/styles/variables' as var;

.forumHeader {
  padding: 10px 0 0;
}

.forumName {
  font-family: var.$geist;
  line-height: 14px;
  letter-spacing: 1.15;
  color: var.$gray;
  margin: 10px 0;
}

.threadlist {
  width: 100%;
}

.threadlink {
  display: block;
}

.postWrapper {
  border-bottom: 1px solid var.$lightGray;
  padding: 10px 0;
}

.postTitle {
  font-size: 16px;
}

.postAuthorContainer {
  display: grid;
  align-items: center;
  color: var.$gray;
  padding: 10px 0;
  font-size: 10px;
  grid-template-areas: 'name comments views';
}

.comments {
  display: flex;
  align-items: center;
  padding: 0 3px;
  grid-area: comments;

  svg {
    margin-right: 3px;
  }
}

.views {
  display: flex;
  align-items: center;
  grid-area: views;

  svg {
    margin-right: 3px;
  }
}

.name {
  grid-area: name;
  margin-right: 2px;
}

.lastPostContainer {
  display: flex;
  color: var.$gray;
}

.lastPostAuthor {
  padding-left: 10px;
}

.linkContainer {
  display: flex;
  padding: 10px 0;
  justify-content: space-between;
}

.viewAllLink {
  padding-bottom: 10px;
}

.allForumsLink,
.viewAllLink {
  font-size: 12px;
  letter-spacing: 2.15px;
  line-height: 14px;
}
