@use '@on3/styles/base';

@use '@on3/styles/mixins' as mix;

@use '@on3/styles/variables' as var;

.block {
  @include mix.spaceY(var.$spacing-regular);

  .logo {
    @include mix.flex($justify: center);

    width: 100%;
    padding-bottom: var.$spacing-sm;

    .nilLogo {
      height: 1.5rem;
      width: auto;
    }
  }

  .list {
    display: grid;
    gap: var.$spacing-regular;
  }

  .links {
    @include mix.flex($justify: space-between);

    a {
      @extend %link-more;

      color: var.$on3primary;
    }
  }
}
