@use '@on3/styles/variables' as var;

.articleContainer {
  background: var.$white;
  padding: 10px;
}

.imagelink {
  margin-bottom: 5px;
  display: block;
}

.image {
  border-radius: 3px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.category {
  padding: 10px 0 5px;
  color: var.$gray;
}

.title {
  font-size: 14px;
  line-height: 21px;
  font-style: normal;
  letter-spacing: 0.35px;
}

.dailyTitle {
  font-size: 17px;
  letter-spacing: 0.15px;
}
