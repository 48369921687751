@use '@on3/styles/variables' as var;

.firstLine {
  font-weight: 700;
  color: var.$night;
  margin-bottom: 5px;
  text-align: center;
}

.secondLine {
  margin-bottom: 5px;
}

.logo {
  height: 30px;
  width: auto;
  margin-bottom: 5px;
}
