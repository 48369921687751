@use '@on3/styles/base';

@use '@on3/styles/mixins' as mix;

@use '@on3/styles/variables' as var;

.block {
  display: grid;
  grid-template-columns: 35px 50px 1fr 70px;
  align-items: center;
  column-gap: var.$spacing-sm;
  letter-spacing: 0.15px;

  @include mix.on3MQ {
    grid-template-columns: 35px 50px 1fr 70px;
  }

  .rank {
    line-height: 50px;
    font-family: var.$archivo-narrow;
    font-weight: 700;
    width: 35px;
    text-align: center;
  }

  .avatar {
    width: 50px;
    height: 50px;
    border-radius: var.$radius-sm;
  }

  .meta {
    @include mix.flex($align: flex-start, $direction: column, $gap: 2px);

    line-height: 1.2;
    min-width: 0;

    .divisionPosition {
      @extend %small-gray-text;

      @include mix.flex($gap: var.$spacing-compact);
    }

    .name {
      color: var.$on3primary;
      font-weight: 600;
      font-size: 0.75rem;
      display: block;
      min-width: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .position {
      @extend %small-gray-text;
    }
  }

  .stats {
    @include mix.flex($direction: column, $gap: var.$spacing-xs);

    text-align: center;
    grid-column: 4;

    .valuation {
      font-size: 0.875rem;
      font-weight: 700;
    }

    .followers {
      @extend %small-gray-text;
    }
  }
}
