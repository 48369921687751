@use '@on3/styles/variables' as var;

.wrapper {
  background-color: var.$white;
  margin-bottom: 10px;
}

.label {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 10px;
}

.headliner {
  border-bottom: 1px solid var.$lightNeutral;
}
