@use '@on3/styles/base';

@use '@on3/styles/variables' as var;

.sidebarContainer {
  padding: 10px;
}

.viewAll {
  font-family: var.$geist;
  line-height: 14px;
  letter-spacing: 1.15px;
}

.commitsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  h3 {
    @extend %h3;
  }
}

.footer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 5px;
  margin-top: 10px;
  justify-content: space-between;
}

.latestCommitsList {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 100%;
}

.latestPlayerContainer {
  display: flex;
  margin-top: 5px;
  padding: 10px;
  gap: 0.5rem;
  background-color: var.$white;
  box-shadow: 1px 1px 3px rgba(0 0 0 / 12%);
  color: var.$gray;
  font-size: 10px;
}

.infoWrapper {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.sportsWrapper {
  display: flex;
  align-items: center;
  gap: 3px;
}

.sportIcon {
  width: 15px;
  font-size: 1rem;
}

.vitalWrapper {
  display: flex;
  gap: 3px;
  flex-wrap: wrap;
}

.avatarContainer {
  display: flex;
  flex-shrink: 0;

  .playerAvatar {
    height: 50px;
    width: 50px;
    border-radius: 3px;
  }
}

.teamContainer {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.playerName {
  display: flex;
  align-items: center;
  gap: 5px;
}

.playerLink {
  font-family: var.$geist;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0.15px;
}

.playerVitals {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.divide {
  margin: 0 2px;
}

.rating {
  font-size: 12px;
  line-height: 16px;
}

.classYear {
  font-weight: bold;
}

.arrow {
  width: 15px;
}

.playerStarRating {
  display: flex;
  gap: 3px;
  align-items: center;

  svg {
    font-size: 0.875rem;
    height: 1em;
    width: 1em;
    margin-bottom: 1px;
  }
}

.image {
  height: 20px;
  width: 20px;
}
