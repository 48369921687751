@use '@on3/styles/base';

@use '@on3/styles/variables' as var;

@use '@on3/styles/mixins' as mix;

.block {
  font-size: 0.75rem;
  display: grid;
  grid-template-columns: 50px 20px 25px 1fr auto 60px;
  gap: var.$spacing-xs;
  align-items: center;

  @extend %small-gray-text;

  .logo {
    width: 20px;
    height: 20px;
    object-fit: contain;
    object-position: center;
    justify-self: center;
  }

  .name {
    color: var.$black;

    a:hover {
      text-decoration: underline;
    }
  }

  .info {
    text-align: right;
  }

  .result {
    margin-right: var.$spacing-xs;
  }
}

[data-game-status='Completed'] {
  .win {
    color: var.$positiveDay;
  }

  .loss {
    color: var.$negativeDay;
  }
}
